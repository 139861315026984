import {
  Avatar,
  AvatarDataset,
  CheckoutData,
  Conversation,
  Creator,
  Endpoints,
  Feedback,
  Knowledge,
  LeadList,
  Message,
  Method,
  OutreachCampaign,
  OutreachCampaignTemplate,
  QueryParams,
  ReturnTypes,
  SecondPathParam,
  Video,
} from "../types"
import { Agent } from "../types/Agent"
import { UsageDetails } from "../types/Usage"
import { firebaseAuth, isDev, isLocalDev } from "../utils"

const backendUrlBaseUrl = isLocalDev
  ? "http://localhost:8000/"
  : isDev
    ? "https://api-dev.bey.chat/"
    : "https://api.bey.chat/"

const callApi = async (
  endpoint: Endpoints,
  method = Method.GET,
  id?: string,
  body?: any,
  secondPathParam?: SecondPathParam,
  returnType = ReturnTypes.json,
  queryParams?: Record<string, string | number>,
) => {
  const idToken = await firebaseAuth?.currentUser?.getIdToken()
  if (!idToken) {
    throw new Error("User is not authenticated")
  }

  const url = new URL(
    secondPathParam
      ? `${backendUrlBaseUrl}${endpoint}/${id}/${secondPathParam}`
      : id
        ? `${backendUrlBaseUrl}${endpoint}/${id}`
        : `${backendUrlBaseUrl}${endpoint}`,
  )

  if (queryParams) {
    Object.entries(queryParams).forEach(([key, value]) => {
      url.searchParams.append(key, value?.toString())
    })
  }

  const response = await fetch(url.toString(), {
    method,
    body: body ? JSON.stringify(body) : undefined,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
    },
  })
  if (response.status !== 200) {
    const json = await response.json()
    throw new Error(`${response.status}: ${(json as any).detail}`)
  }
  if (returnType === ReturnTypes.blob) {
    return response.blob()
  } else {
    return response.json()
  }
}

const transformDate = (e: any) => {
  return {
    ...e,
    updatedAt: e.updatedAt != null ? new Date(e.updatedAt) : null,
    createdAt: e.createdAt != null ? new Date(e.createdAt) : null,
  }
}

const transformDates = (res: any) => {
  return res
    .map((e) => transformDate(e))
    .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
}

export const listAvatars = async (): Promise<Avatar[]> => {
  const avatars = await callApi(
    Endpoints.avatar,
    Method.GET,
    undefined,
    undefined,
    undefined,
    ReturnTypes.json,
    { limit: "1000", last_id: null },
  )
  return transformDates(avatars)
}

export const createAvatar = async (avatar: Avatar) => {
  return transformDate(
    await callApi(Endpoints.avatar, Method.POST, undefined, avatar),
  )
}

export const updateAvatar = async (avatar: Avatar) => {
  return transformDate(
    await callApi(Endpoints.avatar, Method.PUT, avatar.id, avatar),
  )
}

export const generateAvatar = async (avatar: Avatar) => {
  return transformDate(
    await callApi(
      Endpoints.avatar,
      Method.POST,
      avatar.id,
      undefined,
      SecondPathParam.generate,
    ),
  )
}

export const getAvatar = async (avatarId: string): Promise<Avatar> => {
  const avatarFromBe = await callApi(Endpoints.avatar, Method.GET, avatarId)
  return transformDate(avatarFromBe)
}

export const deleteAvatar = async (avatar: Avatar) => {
  return callApi(Endpoints.avatar, Method.DELETE, avatar.id)
}

export const createDataset = async (dataset: AvatarDataset) => {
  return callApi(Endpoints.dataset, Method.POST, undefined, dataset)
}

export const uploadDataset = async (dataset: AvatarDataset, file: File) => {
  const idToken = await firebaseAuth.currentUser.getIdToken()
  const chunk_size = 1024 * 1024 * 10 // Chunk size set to 10 MB
  let offset = 0
  let chunk_number = 0
  while (offset < file?.size) {
    const chunk = file.slice(offset, offset + chunk_size)

    const chunk_blob = new Blob([chunk], { type: file.type })

    // Create a FormData object to send chunk data
    const formData = new FormData()
    formData.append("file", chunk_blob, file.name)
    formData.append("chunk_number", String(chunk_number))
    formData.append("total_chunks", String(Math.ceil(file?.size / chunk_size)))

    await fetch(
      `${backendUrlBaseUrl}${Endpoints.dataset}/${dataset.id}/${SecondPathParam.upload}`,
      {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      },
    )

    offset += chunk_size
    chunk_number += 1
  }

  return
}

export const processDataset = async (dataset: AvatarDataset) => {
  return callApi(
    Endpoints.dataset,
    Method.POST,
    dataset.id,
    undefined,
    SecondPathParam.process,
  )
}

export const getDataset = async (datasetId: string) => {
  return callApi(Endpoints.dataset, Method.GET, datasetId)
}

export const getTemplate = async (templateId: string) => {
  return callApi(Endpoints.template, Method.GET, templateId)
}

export const listTemplates = async (): Promise<OutreachCampaignTemplate[]> => {
  const templates = await callApi(Endpoints.template)
  return transformDates(templates)
}

export const createTemplate = async (template: OutreachCampaignTemplate) => {
  return callApi(Endpoints.template, Method.POST, undefined, template)
}

export const generateTemplateSample = async (
  template: OutreachCampaignTemplate,
) => {
  return callApi(
    Endpoints.template,
    Method.GET,
    template.id,
    undefined,
    SecondPathParam.download_sample,
    ReturnTypes.blob,
  )
}

export const listCampaigns = async (
  queryParams: QueryParams,
): Promise<OutreachCampaign[]> => {
  const campaigns = await callApi(
    Endpoints.campaign,
    Method.GET,
    undefined,
    undefined,
    undefined,
    ReturnTypes.json,
    queryParams,
  )
  return transformDates(campaigns)
}

export const updateCampaign = async (campaign: OutreachCampaign) => {
  return callApi(Endpoints.campaign, Method.PUT, campaign.id, {
    ...campaign,
    updatedAt: new Date(),
  })
}

export const getCampaign = async (campaignId: string) => {
  const campaign = await callApi(Endpoints.campaign, Method.GET, campaignId)
  return transformDate(campaign)
}

export const createCampaign = async (
  campaign: OutreachCampaign,
): Promise<OutreachCampaign> => {
  const campaignFromBe = await callApi(
    Endpoints.campaign,
    Method.POST,
    undefined,
    campaign,
  )
  return transformDate(campaignFromBe)
}

export const deleteCampaign = async (campaign: OutreachCampaign) => {
  return callApi(Endpoints.campaign, Method.DELETE, campaign.id)
}

export const generateCampaignVideos = async (campaign: OutreachCampaign) => {
  return callApi(
    Endpoints.campaign,
    Method.POST,
    campaign.id,
    undefined,
    SecondPathParam.generate_videos,
  )
}

export const generateCampaignText = async (campaign: OutreachCampaign) => {
  const campaignFromBe = await callApi(
    Endpoints.campaign,
    Method.POST,
    campaign.id,
    undefined,
    SecondPathParam.generate_text,
  )
  return transformDate(campaignFromBe)
}

export const campaignDownloadAllVideos = async (campaign: OutreachCampaign) => {
  return callApi(
    Endpoints.campaign,
    Method.GET,
    campaign.id,
    undefined,
    SecondPathParam.download_all_videos,
    ReturnTypes.blob,
  )
}

export const campaignGenerateCSV = async (campaign: OutreachCampaign) => {
  return callApi(
    Endpoints.campaign,
    Method.POST,
    campaign.id,
    undefined,
    SecondPathParam.generate_csv,
    ReturnTypes.blob,
  )
}

const loadMediaUrl = async (url: string) => {
  const idToken = await firebaseAuth.currentUser.getIdToken()
  const result = await fetch(url, {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  })

  const blob = await result.blob()
  return URL.createObjectURL(blob)
}

export const getVideo = async (videoId: string): Promise<Video> => {
  const vid = await callApi(Endpoints.video, Method.GET, videoId)
  return transformDate(vid)
}

export const listVideos = async (
  queryParams: QueryParams,
): Promise<Video[]> => {
  const videos = await callApi(
    Endpoints.video,
    Method.GET,
    undefined,
    undefined,
    undefined,
    ReturnTypes.json,
    queryParams,
  )
  return transformDates(videos)
}

export const createVideo = async (video: Video) => {
  const vid = await callApi(Endpoints.video, Method.POST, undefined, video)
  return transformDate(vid)
}

export const deleteVideo = async (video: Video) => {
  return callApi(Endpoints.video, Method.DELETE, video.id)
}

export const updateVideo = async (video: Video) => {
  const vid = await callApi(Endpoints.video, Method.PUT, video.id, video)
  return transformDate(vid)
}

export const generateVideo = async (video: Video) => {
  return callApi(
    Endpoints.video,
    Method.POST,
    video.id,
    undefined,
    SecondPathParam.generate,
  )
}

export const createLeadList = async (leadList: LeadList) => {
  return callApi(Endpoints.lead_list, Method.POST, undefined, leadList)
}

export const getLeadList = async (leadListId: string) => {
  return callApi(Endpoints.lead_list, Method.GET, leadListId)
}

export const createCreator = async (creator: Creator) => {
  return callApi(Endpoints.creator, Method.POST, undefined, creator)
}

export const getCreator = async (creatorId: string) => {
  return callApi(Endpoints.creator, Method.GET, creatorId)
}

export const updateCreator = async (creator: Creator) => {
  return callApi(Endpoints.creator, Method.PUT, creator.id, creator)
}

export const createCheckoutSession = async (checkoutData: CheckoutData) => {
  return callApi(
    Endpoints.create_checkout_session,
    Method.POST,
    undefined,
    checkoutData,
  )
}

export const checkCheckoutResult = async (sessionId: string) => {
  return callApi(Endpoints.create_checkout_session, Method.GET, sessionId)
}

export const checkCurrentUsage = async (
  creatorId: string,
): Promise<UsageDetails> => {
  return callApi(
    Endpoints.creator,
    Method.GET,
    creatorId,
    undefined,
    SecondPathParam.usage,
  )
}

export const getStripePortalUrl = async (
  creatorId: string,
  returnUrl: string,
) => {
  return callApi(
    Endpoints.creator,
    Method.GET,
    creatorId,
    undefined,
    SecondPathParam.stripe_portal_url,
    ReturnTypes.json,
    { return_url: returnUrl },
  )
}

export const createStripeSession = async (creatorId: string) => {
  return callApi(
    Endpoints.creator,
    Method.GET,
    creatorId,
    undefined,
    SecondPathParam.stripe_session,
  )
}

export const listAgents = async (
  queryParams: QueryParams,
): Promise<Agent[]> => {
  const agents = await callApi(
    Endpoints.agent,
    Method.GET,
    undefined,
    undefined,
    undefined,
    ReturnTypes.json,
    queryParams,
  )
  return transformDates(agents)
}

export const createAgent = async (agent: Agent) => {
  return callApi(Endpoints.agent, Method.POST, undefined, agent)
}

export const updateAgent = async (agent: Agent) => {
  return callApi(Endpoints.agent, Method.PUT, agent.id, agent)
}

export const deleteAgent = async (agentId: string) => {
  return callApi(Endpoints.agent, Method.DELETE, agentId)
}

export const generateAgent = async (agentId: string) => {
  return callApi(
    Endpoints.agent,
    Method.POST,
    agentId,
    undefined,
    SecondPathParam.generate,
  )
}

export const listKnowledge = async (queryParams: QueryParams) => {
  const knowledge = await callApi(
    Endpoints.knowledge,
    Method.GET,
    undefined,
    undefined,
    undefined,
    ReturnTypes.json,
    queryParams,
  )
  return transformDates(knowledge)
}

export const getKnowledge = async (knowledgeId: string) => {
  return callApi(Endpoints.knowledge, Method.GET, knowledgeId)
}

export const createKnowledge = async (knowledge: Knowledge) => {
  return callApi(Endpoints.knowledge, Method.POST, undefined, knowledge)
}

export const deleteKnowledge = async (knowledgeId: string) => {
  return callApi(Endpoints.knowledge, Method.DELETE, knowledgeId)
}

export const uploadKnowledge = async (knowledgeId: string, file: File) => {
  const idToken = await firebaseAuth.currentUser?.getIdToken()
  const chunk_size = 1024 * 1024 * 10 // Chunk size set to 10 MB
  let offset = 0
  let chunk_number = 0
  const total_chunks = Math.ceil(file.size / chunk_size)

  while (offset < file.size) {
    const chunk = file.slice(offset, offset + chunk_size)
    const chunk_blob = new Blob([chunk], { type: file.type })

    // Create a FormData object to send chunk data
    const formData = new FormData()
    formData.append("file", chunk_blob, file.name)
    formData.append("chunk_number", String(chunk_number))
    formData.append("total_chunks", String(total_chunks))

    const response = await fetch(
      `${backendUrlBaseUrl}${Endpoints.knowledge}/${knowledgeId}/${SecondPathParam.upload}`,
      {
        method: Method.POST,
        body: formData,
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      },
    )

    if (response.status !== 200) {
      const json = await response.json()
      throw new Error(`${response.status}: ${(json as any).detail}`)
    }

    offset += chunk_size
    chunk_number += 1
  }

  return { message: "File uploaded successfully" }
}

export const processKnowledge = async (knowledgeId: string) => {
  return callApi(
    Endpoints.knowledge,
    Method.POST,
    knowledgeId,
    undefined,
    SecondPathParam.process,
  )
}

export const listConversations = async (
  agentIds: string[],
): Promise<Conversation[]> => {
  const body = { agent_ids: agentIds }
  const endpoint = `${Endpoints.agent}/${Endpoints.calls}` as Endpoints
  const conversations = await callApi(endpoint, Method.POST, undefined, body)
  return transformDates(conversations)
}

export const listAgentConversations = async (
  agentId: string,
): Promise<Conversation[]> => {
  const conversations = await callApi(
    Endpoints.agent,
    Method.GET,
    agentId,
    undefined,
    SecondPathParam.calls,
  )
  return transformDates(conversations)
}

export const deleteConversation = async (conversationId: string) => {
  return callApi(Endpoints.calls, Method.DELETE, conversationId)
}

export const listMessages = async (
  conversationId: string,
): Promise<Message[]> => {
  const endpoint = `${Endpoints.agent}/${Endpoints.calls}` as Endpoints
  const messages = await callApi(
    endpoint,
    Method.GET,
    conversationId,
    undefined,
    SecondPathParam.messages,
  )
  const transformedMessages = messages.map((msg) => ({
    ...msg,
    sentAt: msg.sentAt ? new Date(msg.sentAt) : null,
  }))

  return transformedMessages.sort(
    (a, b) => (a.sentAt?.getTime() || 0) - (b.sentAt?.getTime() || 0),
  )
}

export const createFeedback = async (feedback: Partial<Feedback>) => {
  return callApi(Endpoints.feedback, Method.POST, undefined, feedback)
}

export const getFeedbacks = async (
  queryParams: QueryParams,
): Promise<Feedback[]> => {
  return callApi(
    Endpoints.feedback,
    Method.GET,
    undefined,
    undefined,
    undefined,
    ReturnTypes.json,
    queryParams,
  )
}

export const getStripeProducts = async () => {
  const endpoint =
    `${Endpoints.create_checkout_session}/${SecondPathParam.products}` as Endpoints

  const products = await callApi(endpoint, Method.GET)
  return products
}
