import React from "react"

import {
  Avatar,
  Box,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { ArrowLeft } from "@phosphor-icons/react"
import { useNavigate } from "react-router-dom"
import { Logo, TextIconButton } from "../../components"
import { useAppDispatch, useAppSelector } from "../../redux"
import { AppActions } from "../../redux/appSlice"
import { signUserOut } from "../../services"
import { Colors } from "../../types"

export function Header({
  showTopbar = true,
  onBackPress,
}: {
  showTopbar?: boolean
  onBackPress?: () => void
}) {
  const user = useAppSelector((state) => state.app.user)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const signOut = async () => {
    navigate("/")
    await signUserOut()
    dispatch(AppActions.resetState())
  }

  return (
    <>
      {isMobile ? (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ height: "10vh" }}
        >
          <Logo />
        </Stack>
      ) : (
        <>
          {showTopbar && (
            <Toolbar
              sx={{
                height: "2.5rem",
                pt: "1.5rem",
                pb: "1.5rem",
                borderBottom: 1,
                borderBottomColor: Colors.grey200,
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                }}
              >
                {onBackPress && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => onBackPress()}
                  >
                    <ArrowLeft color="black" />
                    <Typography
                      sx={{ ml: 1, color: Colors.almostBlack }}
                      variant="body1"
                    >
                      Back
                    </Typography>
                  </Box>
                )}
              </Box>
              {user != null ? (
                <Box sx={{ dislay: "flex", alignItems: "center", flexGrow: 0 }}>
                  <Stack direction="row" spacing={2}>
                    <Stack alignItems="flex-end">
                      <Typography variant="body2" sx={{ mr: 1 }}>
                        {user.email}
                      </Typography>
                      <TextIconButton text="Switch account" onClick={signOut} />
                    </Stack>
                    <Avatar>{user.email[0]}</Avatar>
                  </Stack>
                </Box>
              ) : null}
            </Toolbar>
          )}
        </>
      )}
    </>
  )
}
