import { getAnalytics } from "firebase/analytics"
import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"
import { isDev } from "./general"

const firebaseConfig = isDev
  ? {
      apiKey: "AIzaSyC788lZ2gkp8HjPNDhpWNBHsLCVUGaOksY",
      authDomain: "bey-dev.firebaseapp.com",
      projectId: "bey-dev",
      storageBucket: "bey-dev.appspot.com",
      messagingSenderId: "347029147333",
      appId: "1:347029147333:web:8c9c85b285179c0be4eaac",
      measurementId: "G-NBGKVM0BES",
    }
  : {
      apiKey: "AIzaSyCx2GDcIjQQ9nTtIbHHf6wGwvnp_2V7jX4",
      authDomain: "bey-prod.firebaseapp.com",
      projectId: "bey-prod",
      storageBucket: "bey-prod.appspot.com",
      messagingSenderId: "352012720018",
      appId: "1:352012720018:web:27a46e753a2e56983f534b",
      measurementId: "G-BN8E3VD31V",
    }

const firebaseApp = initializeApp(firebaseConfig)
export const firebaseAnalytics = getAnalytics(firebaseApp)
export const firebaseAuth = getAuth(firebaseApp)
export const firebaseDb = getFirestore(firebaseApp)
export const firebaseStorage = getStorage(firebaseApp)
firebaseAuth.useDeviceLanguage()
